<template>
    <teleport to='#modal'>
        <div v-if="add_modal" class="bg-gray-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 text-gray-600">
            <div :class="{ 'scale-out-center': leaving_modal }" class="scale-in-center bg-brown-100 px-4 py-5 rounded-md text-center">
                <form class="">
                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">Category Name</p>
                    </div>
                    <div class="gap-4">
                        <div class="form-group mb-6">
                            <input type="text" v-model="name" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="name">
                        </div>                                                        
                    </div>  

                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">Image</p>
                    </div>
                    <div class="gap-4">
                        <div class="form-group mb-6">
                            <input type="file" accept="image/*" @change="selectImage($event)" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="name">
                        </div>                                                        
                    </div>                      
                </form>
                <button @click="leaving_modal = true, turnOffAddModal()" class="bg-red-500 px-4 py-2 rounded-md text-md text-white">Cancel</button>
                <button @click="saveCategory()" class="bg-brown-default px-7 py-2 ml-2 rounded-md text-md text-white font-semibold">Save</button>
            </div>
        </div>
    </teleport>
    <!-- End of new category -->

    <teleport to='#modal'>
        <div v-if="update_modal" class="bg-gray-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 text-gray-600">
            <div :class="{ 'scale-out-center': leaving_modal }" class="scale-in-center bg-brown-100 px-4 py-5 rounded-md text-center">
                <form class="">
                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">Category Name</p>
                    </div>
                    <div class="gap-4">
                        <div class="form-group mb-6">
                            <input type="text" v-model="name" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="name">
                        </div>                                                        
                    </div>                       
                </form>
                <button @click="leaving_modal = true, turnOffModal()" class="bg-red-500 px-4 py-2 rounded-md text-md text-white">Cancel</button>
                <button @click="categoryUpdateRequest()" class="bg-brown-default px-7 py-2 ml-2 rounded-md text-md text-white font-semibold">Modify</button>
            </div>
        </div>
    </teleport>
    <!-- End of modify category -->

    <div class="p-4">
        <top-search></top-search>

        <div class="w-full flex justify-between my-4 p-4">
            <label for="users" class="font-semibold">Category</label>
            <div class="flex justify-between">
                <button class="px-5 py-2 rounded-lg hover:outline">
                    Export
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
                <button @click="leaving_modal = false, add_modal = true" class="ml-4 bg-brown-default text-gray-50 px-5 py-2 rounded-lg">Add Category</button>
            </div>
        </div>

        <div v-if="categories" class="w-full my-12 p-4">
            <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">                
                <div class="block w-full overflow-x-auto ">
                    <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th class="px-4 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">#</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Name</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Status</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date Created</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date Updated</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="bg-white" v-for="(category, index) in categories" :key="category.id">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <span class="font-bold"> {{ index + 1 }} </span>
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ category.name }}</td>
                                <td v-if="category.status" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">Inactive</td>
                                <td v-else class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">Active</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ moment(category.created_at).format("DD, MMM YYYY") }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ moment(category.updated_at).format("DD, MMM YYYY") }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                    <div class="flex">
                                        <div>
                                            <div class="dropstart relative">
                                                <button @click="id = category.id, name = category.name" class="dropdown-toggle text-brown-default font-bold text-lg uppercase focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">...
                                                </button>
                                                <ul class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left
                                                    rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none" aria-labelledby="dropdownMenuButton1">
                                                    <!-- <li data-bs-toggle="modal" data-bs-target="#exampleModal"> -->
                                                    <li @click="leaving_modal = false, update_modal = true">
                                                        <button class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                                                            Edit
                                                        </button>
                                                    </li>
                                                    <li class="">
                                                        <button class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                                                            Delete
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'
import moment from 'moment'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
export default {
    name: 'category',
    components: {
        TopSearch,
        SquareGrid
    },
    data() {
        return {
            id: '',
            name: '',
            image: '',
            dropdown: false,
            moment: moment,
            update_modal: false,
            add_modal: false,
            leaving_modal: false,
        }
    },
    computed: {
        ...mapGetters({
            categories: 'categoriesModule/getCategories',
            updated: 'categoriesModule/getUpdated',
            error: 'categoriesModule/getError',
            created: 'categoriesModule/getCreated',
        })
    },
    methods: {
        selectImage(e) {
            this.image = e.target.files[0]
        },

        saveCategory() {
            let formData = new FormData();
            formData.append("name", this.name);
            formData.append("image", this.image);

            this.$store.dispatch('categoriesModule/categoryStoreRequest', formData)
            .then(() => { 
                if (this.created == 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: this.created.message,
                    })
                    this.turnOffAddModal()
                } 
                if(this.error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Unable to complete this request',
                        text: this.error.message
                    })                    
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "An error occured..."
                })
                
            })
        },

        categoryUpdateRequest() {
            const data = { id: this.id, name: this.name }
            this.$store.dispatch('categoriesModule/categoryUpdateRequest', data)
            .then(() => { 
                if (this.updated.status == 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: this.updated.message,
                    })
                    this.turnOffModal()
                } 
                if(this.error.status == 400) {
                    Swal.fire({
                        icon: 'error',
                        title: this.error.message,
                        text: this.error.data
                    })                    
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "An error occured..."
                })
                
            })
        },

        logout() {
            this.$store.dispatch('authModule/logoutRequest')
            
            this.$router.push({name: 'Login'})
            
        },

        turnOffAddModal() {
            setTimeout(() => {
                this.add_modal = false;
            }, 500)
            
        },

        turnOffModal() {
            setTimeout(() => {
                this.update_modal = false;
            }, 500)
            
        },
    }
}
</script>


<style scoped>
    .scale-in-center {
        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    .scale-out-center {
        -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }

    @-webkit-keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
    }

    @keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
    }


</style>
